export const orderOption = {
  menu: false,
  maxHeight: 300,
  column: [
    {
      prop: 'productLabelBarcode',
      label: 'FNSKU',
      slot: true
    },
    {
      prop: 'productCnName',
      label: '商品信息',
      slot: true,
      minWidth: 120
    },
    {
      prop: 'knifeLayoutPath',
      label: '刀版图',
      slot: true
    },

    {
      prop: 'productExternalSkuCode',
      label: 'SKU'
    },

    {
      prop: 'internalUnitPrice',
      label: '单价',
      slot: true
    },
    {
      prop: 'unitNetWeight',
      label: '重量',
      slot: true
    },
    {
      prop: 'productStructName',
      label: '款式',
      slot: true
    },
    {
      prop: 'productSizeName',
      label: '尺码',
      slot: true
    },
    {
      prop: 'productCount',
      label: '数量'
    }
  ]
}
